@use "../../styles/mixins" as *;
@include layer(ui) {
    .list-wrapper {
        position: absolute;
        list-style: none;
        padding: var(--g-spacing-sm);
        margin: 0;
        min-width: 100%;
        box-sizing: border-box;
        z-index: 10;
        border-radius: var(--s-border-radius-field-standard);
        background-color: var(--s-color-selection-background-default);
        box-shadow: (var(--s-shadow-standard-default));
        overflow: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 4px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: var(--g-border-radius-sm);
            background-color: var(--s-color-scrollbar-default);
            border: 1px solid var(--s-color-scrollbar-default);
        }
        display: block;
        top: calc(var(--s-size-height-field-standard) + 6px);
    }
    .list-item {
        padding: var(--g-spacing-sm);
        font-family: var(--g-font-family-primary);
        font-weight: var(--g-font-weight-regular);
        font-size: var(--g-font-size-400);
        line-height: var(--g-line-height-400);
        border-radius: var(--s-border-radius-field-standard);
        &::before {
            display: none;
        }
        cursor: "pointer";
        &:hover:not(:disabled),
        &:active:not(:disabled) {
            color: var(--s-color-selection-foreground-active);
            background: var(--s-color-selection-background-active);
        }
        &--highlighted {
            color: var(--s-color-selection-foreground-active);
            background: var(--s-color-selection-background-active);
            outline: transparent solid 1px;
        }
        &--selected {
            color: var(--s-color-selection-foreground-selected-bold);
            background: var(--s-color-selection-background-selected-bold);

            &:active:not(:disabled) {
                color: var(--s-color-selection-foreground-active);
                background: var(--s-color-selection-background-active);
            }
        }
    }
}
